import React from 'react';
import { Link } from 'gatsby';
import StandardLayout from '../layouts/standard';
import PageCover from '../components/page-cover';
import coverImage from '../images/about/our-mission/our-mission-cover.jpg';
import productPlaceholder from '../images/products/product-placeholder.jpg';
import logo1 from '../images/products/logo-1.jpg';
import logo2 from '../images/products/logo-2.jpeg';
import logo3 from '../images/products/logo-3.jpeg';
import logo4 from '../images/products/logo-4.jpeg';
import logo5 from '../images/products/logo-5.png';
import logo6 from '../images/products/logo-6.png';
import logo7 from '../images/products/logo-7.jpeg';
import logo8 from '../images/products/logo-8.jpeg';
import logo9 from '../images/products/logo-9.jpeg';
import logo10 from '../images/products/logo-10.jpeg';
import logo11 from '../images/products/logo-11.jpeg';
import logo12 from '../images/products/logo-12.jpeg';
import logo13 from '../images/products/logo-13.jpeg';
import logo14 from '../images/products/logo-14.jpeg';
import logo15 from '../images/products/logo-15.jpeg';
import size1 from '../images/products/size-1.png';
import size2 from '../images/products/size-2.png';
import size3 from '../images/products/size-3.png';
import size4 from '../images/products/size-4.png';
import size5 from '../images/products/size-5.png';
import size6 from '../images/products/size-6.png';
import size7 from '../images/products/size-7.png';
import size8 from '../images/products/size-8.png';
import { imageExtensions, videoExtensions } from '../utils';

export default function Products(props) {
    const { categories } = require('../data.json');

    return <StandardLayout title="Products">
        <div>
            <PageCover></PageCover>
        </div>
        <section className="uk-section">
            <div className="uk-container">
                <div className="uk-grid uk-grid-medium uk-grid-row-small uk-flex-center uk-flex-between@s uk-flex-wrap" data-uk-grid>
                    {categories.map(cat => <div>
                        <a href={`#${cat.id}`} className="uk-button uk-button-primary uk-wdith-auto@s uk-width-expand">{cat.title}</a>
                    </div>)}
                </div>
            </div>
            <div className="uk-container uk-margin-medium">
                <div className="uk-grid uk-grid-collapse uk-child-width-1-2@s uk-child-width-1-1 uk-card uk-card-default uk-box-shadow-small">
                    {categories.map((cat, index) => {
                        const ret = [
                            <div id={cat.id}>
                                <div className="uk-cover-container uk-width-1-1" style={{height: "100%", minHeight: "400px"}}>
                                {
                                    !cat.thumbnail ? '' :
                                    imageExtensions.find(e => e === cat.thumbnail.ext) ? <img src={cat.thumbnail.publicURL} data-uk-cover className="uk-width-1-1" alt=""/> :
                                    videoExtensions.find(e => e === cat.thumbnail.ext) ? <video src={cat.thumbnail.publicURL} data-uk-cover classname="uk-width-1-1" /> :
                                    ''
                                }
                                </div>
                            </div>,
                            <div className="uk-padding">
                                <h2 className="uk-h2">{cat.title}</h2>
                                <p>{cat.description}</p>
                                <Link to={"/products/" + cat.url}><button className="uk-button uk-button-secondary">Learn More</button></Link>
                            </div>
                        ];
                        return index % 2 === 0 ? ret.reverse() : ret;
                    })}
                </div>
            </div>
        </section>
        <section className="uk-section">
            <div className="uk-container">
                <div className="uk-text-center">
                   <h2 className="uk-h2 uk-text-uppercase">
                    QUALITY CREDENTIALS
                   </h2>
                   <div>
                   Our products undergo rigorous testing to make sure they exceed quality standards and withstand the test of time. 
                   <br/>
                   Our team of architects, product design specialists and interior designers work efficiently toward a common goal -giving you the freedom to style your spaces in every fashion.
                   </div>
                </div>
                <div className="uk-grid uk-grid-medium uk-flex-wrap uk-flex-center uk-margin-medium-top product-quality-logo" data-uk-grid>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo1} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo2} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo3} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo4} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo5} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo6} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo7} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo8} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo9} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo10} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo11} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo12} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo13} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo14} alt=""/>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-box-shadow-small uk-padding-small">
                            <img src={logo15} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="uk-section">
        <div class="uk-cover-container" style={{minHeight: "80vh", height: "auto"}}>
            <img src={coverImage} alt="" data-uk-cover/>
            <div class="uk-overlay-primary uk-position-cover"></div>
            <div class="uk-overlay uk-position-center">
                <div className="uk-container">
                        <div className="uk-card uk-card-default uk-padding-large uk-width-1-1">
                            <div>
                                <div data-uk-grid>
                                    <div class="uk-width-1-3@s uk-width-1-1">
                                        <ul class="uk-tab-left" data-uk-tab="connect: #component-tab-left; animation: uk-animation-fade">
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        930 X 2160 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        1220 X 2440 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        1245 X 2465 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        1300 X 2800 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        1300 X 3050 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        1600 X 3660 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        1860 X 3660 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                                        <div>
                                                        1860 X 4320 mm
                                                        </div>
                                                        <button className="icon-button red">
                                                            <span data-uk-icon="icon: chevron-right"></span>
                                                        </button>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="uk-width-expand@s uk-width-1-1">
                                        <ul id="component-tab-left" class="uk-switcher">
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size1} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Matt
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size2} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Matt, Suede, Gloss, All textures
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size3} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Matt, Gloss, Suede, Suede II
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size4} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Matt, Gloss
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size5} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Suede, Matt, Gloss, Abstract, Carvan Leather, Grand Walnut, Hand Scraped, Lumber, Mirror Wash, Rough Cut, Raw Matt, Saw Cut, Sheen, Shining Oak, Super Matt, Straight Oak, Script, Quartz
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size5} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Matt
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size6} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Suede, Matt, Gloss
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size7} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Matt
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid" data-uk-grid>
                                                    <div className="uk-wdith-auto">
                                                        <img src={size8} style={{maxHeight: "300px"}} alt=""/>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" data-uk-grid>
                                                            <div className="uk-text-bold">
                                                                    FINISHES
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Matt
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    SHEET
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 0.5 to 1.8 mm
                                                            </div>
                                                            <div className="uk-text-bold">
                                                                    COMPACT
                                                            </div>
                                                            <div className="uk-text-meta">
                                                            Thickness from 2.0 to 25 mm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </section>
    </StandardLayout>
}